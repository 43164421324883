// base
@import "./scss/base/variables";
@import "./scss/base/mixins";
@import "./scss/vendors/bootstrap-5.1.3/functions";
@import "./scss/vendors/bootstrap-5.1.3/variables";
@import "./scss/vendors/bootstrap-5.1.3/mixins";
@import "./scss/vendors/bootstrap-5.1.3/mixins/lists";
@import "./scss/vendors/bootstrap-5.1.3/mixins/breakpoints";
@import "./scss/vendors/bootstrap-5.1.3/mixins/container";
@import "./scss/vendors/bootstrap-5.1.3/mixins/grid";
@import "./scss/vendors/bootstrap-5.1.3/mixins/utilities";
@import "./scss/vendors/bootstrap-5.1.3/vendor/rfs";
@import "./scss/vendors/bootstrap-5.1.3/_dropdown";
@import "./scss/base/utilities";

// 3rd libraries
@import "node_modules/ag-grid-community/styles/ag-grid.css";
@import "node_modules/ag-grid-community/styles/ag-theme-material.css";
@import "intl-tel-input/build/css/intlTelInput.css";

// angular them and core
@import "./scss/base/theme";
@import "./scss/angular/angular-core";
@import "./scss/vendors/bootstrap-5.1.3/reboot";

// utilities
@import "./scss/vendors/bootstrap-5.1.3/utilities";
$utilities: map-get-multiple($utilities,
    ("display",
      "order",
      "flex",
      "flex-direction",
      "flex-grow",
      "flex-shrink",
      "flex-wrap",
      "justify-content",
      "align-items",
      "align-content",
      "align-self",
      "margin",
      "margin-x",
      "margin-y",
      "margin-top",
      "margin-end",
      "margin-bottom",
      "margin-start",
      "negative-margin",
      "negative-margin-x",
      "negative-margin-y",
      "negative-margin-top",
      "negative-margin-end",
      "negative-margin-bottom",
      "negative-margin-start",
      "padding",
      "padding-x",
      "padding-y",
      "padding-top",
      "padding-end",
      "padding-bottom",
      "padding-start"
    ));
@import "./scss/vendors/bootstrap-5.1.3/utilities/api";

// elevations
@import "./scss/base/elevations";

// utilities
@import "./scss/utilities/spacing";
@import "./scss/utilities/borders";
@import "./scss/utilities/utilities";
@import "./scss/utilities/colors";
@import "./scss/utilities/helpers";

// layouts
@import "./scss/vendors/bootstrap-5.1.3/containers";
@import "./scss/vendors/bootstrap-5.1.3/grid";
@import "./scss/layout/layout";
@import "./scss/layout/samples";

// typo
@import "./scss/base/fonts";
@import "./scss/base/typography";
@import "./scss/base/typography-old";

// icon
@import "./scss/base/icon";

// old styles
@import "./scss/layout/grids";
@import "./scss/layout/edara-theme";

// components
@import "./scss/components/buttons";
@import "./scss/components/forms";
@import "./scss/components/side-nav";
@import "./scss/components/loading";
@import "./scss/components/dialog";
@import "./scss/components/bottom-sheet";
@import "./scss/components/chat";
@import "./scss/components/drop-down-style";
@import "./scss/components/user-profile";
@import "./scss/components/fixed-assets";
@import "./scss/components/mat-dialog-animation";
@import "./scss/components/progress-bar";
@import "./scss/components/ag-grid";
@import "./scss/components/slide-toggle";
@import "./scss/components/cards";
@import "./scss/components/checkbox";
@import "./scss/components/radiobutton";
@import "./scss/components/switch";
@import "./scss/components/menus";
@import "./scss/components/sliders";
@import "./scss/components/snackbar";
@import "./scss/components/progress-spinner";
@import "./scss/components/divider";
@import "./scss/components/user-menu";
@import "./scss/components/table";
@import "./scss/components/auth";
@import "./scss/components/tabs";
@import "./scss/components/placeholders";
@import "./scss/components/scoped-dialog";
@import "./scss/components/drag-zone.scss";
@import "./scss/components/badges";
@import "./scss/components/date-picker";
@import "./scss/components/lists";
@import "./scss/components/top-app-bars";
@import "./scss/components/chips";
@import "./scss/components/auth.scss";
@import "./scss/components/tooltip.scss";
@import "./scss/components/nav-bars";
@import "./scss/components/modules_landing";
@import "./scss/components/alerts";
@import "./scss/components/filter";
// @import "./scss/theme/theme.scss";