body {

  .mat-mdc-row,
  .mat-mdc-header-row,
  .mat-mdc-footer-row {
    border-color: var(--m3-sys-surface-variant);
  }

  .mat-row:hover {
    background-color: var(--m3-sys-surface-variant);
  }

  .mat-table {
    background-color: var(--m3-sys-surface);
  }

  .mat-mdc-header-cell {
    color: var(--m3-sys-on-surface);
    font-size: var(--typo-label-medium);
    padding-inline: 0;
  }

  .mat-mdc-cell,
  .mat-mdc-footer-cell {
    color: var(--m3-sys-on-surface);
    padding-inline: var(--space-xs);
    font-size: var(--typo-label-large);
  }

  .mat-mdc-paginator,
  .mat-mdc-paginator-page-size .mat-select-trigger {
    /* background-color: var(--m3-sys-surface); */
    background-color: transparent !important;
  }

  .mat-mdc-paginator-page-size-label {
    color: var(--m3-sys-on-surface);
  }

  .mat-form-field-appearance-legacy .mat-form-field-underline {
    background-color: var(--m3-sys-on-surface);
  }

  .mat-mdc-paginator-range-label {
    color: var(--m3-sys-on-surface) !important;
  }

  .mat-button.mat-primary.mat-button-disabled,
  .mat-button.mat-accent.mat-button-disabled,
  .mat-button.mat-warn.mat-button-disabled,
  .mat-button.mat-button-disabled.mat-button-disabled,
  .mat-icon-button.mat-primary.mat-button-disabled,
  .mat-icon-button.mat-accent.mat-button-disabled,
  .mat-icon-button.mat-warn.mat-button-disabled,
  .mat-icon-button.mat-button-disabled.mat-button-disabled,
  .mat-stroked-button.mat-primary.mat-button-disabled,
  .mat-stroked-button.mat-accent.mat-button-disabled,
  .mat-stroked-button.mat-warn.mat-button-disabled,
  .mat-stroked-button.mat-button-disabled.mat-button-disabled {
    color: var(--m3-sys-on-surface);
  }

  .ag-header-container,
  .ag-floating-top-container,
  .ag-floating-bottom-container {
    background-color: var(--m3-sys-surface);
  }

  .ag-theme-material .ag-header-cell,
  .ag-theme-material .ag-header-group-cell {
    background-color: var(--m3-sys-surface);
  }

  .ag-header-group-cell-label,
  .ag-header-cell-label {
    color: var(--m3-sys-on-surface);
  }

  .ag-theme-material .ag-row-odd {
    background-color: var(--m3-sys-surface);
    border-color: var(--m3-sys-surface-variant);
  }

  .ag-theme-material .ag-row {
    background-color: var(--m3-sys-surface);
    border-color: var(--m3-sys-surface-variant);
  }

  .ag-theme-material .ag-cell {
    color: var(--m3-sys-on-surface);
  }

  .ag-theme-material .ag-checkbox-input-wrapper::after {
    color: var(--m3-sys-on-surface);
  }

  .ag-theme-material .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected),
  .ag-theme-material .ag-context-menu-open .ag-cell-focus:not(.ag-cell-range-selected),
  .ag-theme-material .ag-has-focus .ag-full-width-row.ag-row-focus .ag-cell-wrapper.ag-row-group,
  .ag-theme-material .ag-cell-range-single-cell,
  .ag-theme-material .ag-cell-range-single-cell.ag-cell-range-handle,
  .ag-theme-material .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected),
  .ag-theme-material .ag-context-menu-open .ag-cell-focus:not(.ag-cell-range-selected),
  .ag-theme-material .ag-has-focus .ag-full-width-row.ag-row-focus .ag-cell-wrapper.ag-row-group,
  .ag-theme-material .ag-cell-range-single-cell,
  .ag-theme-material .ag-cell-range-single-cell.ag-cell-range-handle {
    border-color: var(--m3-sys-primary);
  }

  .ag-theme-material .ag-checkbox-input-wrapper.ag-checked:after,
  .ag-theme-material .ag-checkbox-input-wrapper.ag-indeterminate:after {
    color: var(--m3-sys-primary);
  }

  .ag-theme-material .ag-header-group-cell:not(.ag-column-resizing)+.ag-header-group-cell:not(.ag-header-cell-moving):hover,
  .ag-theme-material .ag-header-group-cell:not(.ag-column-resizing)+.ag-header-group-cell.ag-column-resizing,
  .ag-theme-material .ag-header-cell:not(.ag-column-resizing)+.ag-header-cell:not(.ag-header-cell-moving):hover,
  .ag-theme-material .ag-header-cell:not(.ag-column-resizing)+.ag-header-cell.ag-column-resizing,
  .ag-theme-material .ag-header-group-cell:first-of-type:not(.ag-header-cell-moving):hover,
  .ag-theme-material .ag-header-group-cell:first-of-type.ag-column-resizing,
  .ag-theme-material .ag-header-cell:first-of-type:not(.ag-header-cell-moving):hover,
  .ag-theme-material .ag-header-cell:first-of-type.ag-column-resizing {
    background-color: var(--m3-sys-surface-variant);
  }

  .ag-theme-material .ag-header-cell .ag-icon {
    color: var(--m3-sys-on-surface);
  }

  .ag-theme-material .ag-header {
    border-color: var(--m3-sys-surface-variant);
  }

  .ag-body-viewport {
    background-color: var(--m3-sys-surface);
  }

  .ag-overlay-no-rows-center {
    color: var(--m3-sys-on-surface);
  }
}

.mat-paginator-icon {
  fill: var(--m3-sys-on-surface-variant) !important;
}

.custom-tabs-card .mat-mdc-card {
  padding: unset;
  background: unset;
  box-shadow: unset !important;
}

// .mat-badge-accent .mat-badge-content{
//   background: var(--m3-sys-dark-outline) !important;
// }
.table-bg-none {
  &.mat-table {
    background-color: transparent !important;
  }
}

body {
  .ag-grid-filter-table {

    .ag-header-cell-menu-button {
      display: none;
    }

    .mat-paginator-container {
      justify-content: space-between;
    }

    .ag-header-cell-text {
      font-weight: 500;
    }

    .ag-header-cell-label {
      justify-content: start;
    }

    .mat-paginator-range-label,
    .mat-paginator-page-size-label,
    .mat-select-value-text {
      font-size: var(--typo-title-small);
    }
  }
}

.hide-table-scroll {
  .ag-body-horizontal-scroll-viewport {
    @include media-breakpoint-down(sm) {
      overflow-x: hidden !important;
    }
  }
}

.cash-management {
  .ag-virtual-list-container {
    height: calc(100% + 56px) !important;
    overflow: auto;
  }
}



body .table-row-templates {
  border: 0;

  th,
  td {
    border: 0;
    flex: 1;
    display: flex;
    align-items: center;
    overflow: hidden;
    word-wrap: break-word;
    min-height: inherit;
  }

  td {
    padding-inline: var(--space-xs);
  }

  tr {
    display: flex;
    flex-wrap: wrap;
    border-width: 0;
    border-bottom-width: 1px;
    border-style: solid;
    box-sizing: border-box;

  }



  tr.mat-row,
  tr.mat-footer-row {
    height: auto;
    padding: var(--space-xs) var(--space-none);
  }
}


body .table-row-templates--footer tr.mat-mdc-row,
body .table-row-templates--footer tr.mat-mdc-footer-row {
  min-height: 48px;
  padding: var(--space-xs) var(--space-none);
  height: auto;
}

body .input-popover__body {

  .table-row-templates tr.mat-mdc-row,
  .table-row-templates tr.mat-mdc-footer-row {
    height: 48px;
  }
}

.table-td-start .ag-ltr .ag-cell {
  justify-content: start;
  font-size: var(--typo-label-large);
}

.table-td-start {
  .ag-row:hover {
    background-color: var(--m3-sys-surface-variant);
  }
}


body .table-background-transparent {

  .ag-theme-material .ag-header-cell,
  .ag-theme-material .ag-header-group-cell {
    background: transparent;
  }

  .ag-header-container,
  .ag-floating-top-container,
  .ag-floating-bottom-container {
    background: transparent;
  }

  .ag-theme-material .ag-header {
    background: transparent;
  }

  .ag-theme-material .ag-root-wrapper {
    background: transparent;
  }

  .ag-theme-material .ag-row {
    background: transparent;
  }

  .ag-center-cols-viewport {
    background: transparent;
  }
}

.custom-table-height {
  .mat-mdc-row {
    height: auto;
  }

  .mat-mdc-input-element {
    margin: 0;
  }

  .mat-mdc-icon-button[disabled] .mat-mdc-paginator-icon {
    fill: var(--m3-sys-on-surface-variant);
  }

  .mat-mdc-icon-button[disabled],
  .mat-mdc-icon-button.mat-mdc-button-disabled {
    opacity: .38;
  }

  .mat-mdc-form-field-type-mat-select:not(.mat-form-field-disabled) .mat-mdc-text-field-wrapper {
    padding: 0;
  }

  .mat-mdc-paginator-container {
    .mdc-text-field--outlined .mdc-notched-outline {
      padding: 0;
      border-bottom: 1px solid var(--m3-sys-on-surface);
    }
  }

  .mat-mdc-table {
    background-color: var(--m3-sys-surface);
  }
}

th.mat-mdc-header-cell:first-of-type,
td.mat-mdc-cell:first-of-type,
td.mat-mdc-footer-cell:first-of-type {
  padding-inline-start: var(--space-m);
}

th.mat-mdc-header-cell:last-of-type,
td.mat-mdc-cell:last-of-type,
td.mat-mdc-footer-cell:last-of-type {
  padding-inline-end: var(--space-m);
}

.table__checkbox-cell {
  .mdc-checkbox {
    width: 16px;
    height: 16px;
  }

  .mdc-checkbox .mdc-checkbox__native-control {
    width: 16px;
    height: 16px;
  }

  .mdc-checkbox .mdc-checkbox__background {
    top: 0;
    left: 0;
  }
}

.mat-mdc-card {
  .mat-mdc-footer-cell {
    background: none;
  }
}

body {
  .mat-mdc-paginator-icon {
    fill: var(--m3-sys-on-surface);
  }

  .mat-mdc-icon-button[disabled] .mat-mdc-paginator-icon {
    fill: var(--m3-sys-on-surface);
    opacity: .4;
  }

  .mat-mdc-table {
    background-color: var(--m3-sys-surface);
  }

  .mat-mdc-cell,
  .mat-mdc-header-cell {
    border-bottom-color: var(--m3-sys-surface-variant);
  }

  .ag-row-hover:not(.ag-full-width-row)::before,
  .ag-row-hover.ag-full-width-row.ag-row-group::before {
    background-color: var(--m3-sys-surface-variant);
  }

  .ag-row-selected:not(.row-highlight)::before {
    background-color: var(--m3-sys-inverse-on-surface);
  }
}

body .mat-mdc-header-row {
  line-height: inherit;
}

body .mat-mdc-row {
  line-height: inherit;
  height: auto;
}

.row-highlight:not(.ag-row-selected) {
  background-color: var(--color-surface-5) !important;
}

.mat-mdc-table .mat-mdc-cell {
  background-color: transparent;
}