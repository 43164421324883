// Colors
$color-primary: #0091ea;
$color-secondary: #ededed;
$color-white: #fff;
$color-warn: var(--m3-sys-error);

// paths
$img-path: '/assets/images';
$font-path: '/assets/fonts';
$font-family: var(--font-family);
$light-theme: '.light-theme';
$dark-theme: '.dark-theme';

//  selection background color
$selection-color: darken($color-secondary, 15%);

//## Define the breakpoints at which your layout will change, adapting to different screen sizes.
$screen-xs: 0 !default;
$screen-sm: 576px !default;
$screen-md: 768px !default;
$screen-lg: 992px !default;
$screen-xl: 1200px !default;
$screen-wd: 1600px !default;

// Spinners

$spinner-width:         2rem !default;
$spinner-height:        $spinner-width !default;
$spinner-border-width:  .25em !default;

$spinner-width-sm:        1rem !default;
$spinner-height-sm:       $spinner-width-sm !default;
$spinner-border-width-sm: .2em !default;

$sizes: (
  "auto": auto,
  "none": 0,
  // 0
  "xs": 0.25rem,
  // 4px
  "s": 0.5rem,
  // 8px
  "base": 1rem,
  // 16px
  "m": 1.5rem,
  // 24px
  "l": 2rem,
  // 32px
  "xl": 2.5rem,
  // 40px
  "xxl": 3rem,
  // 48px
  "xxxl": 5rem,
  // 80px
);

$directions: (
  "top": top,
  "bottom": bottom,
  "start": inline-start,
  "end": inline-end,
  "right": right,
  "left": left,
  "y": "",
  "x": "",
);

$border-radii: (
  "none": 0,
  "tiny": 4px,
  "soft": 6px,
  "m": 13px,
  "rounded": 28px,
  "circle": 50%,
);

$border-sizes: (
  "none": 0,
  "base": 1px,
  "m": 2px,
  "l": 3px,
  "xl": 6px,
);
