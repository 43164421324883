  /* bootstrap fix */
  button:focus {
    outline: none;
  }

  /* fix theme issue */
  :root {
    --primary: #0091ea;
    --accent: #404041;
  }

  // .mat-toolbar.mat-accent{
  //     background-color: var(--accent);
  // }
  .l-header {
    position: relative;
  }

  // .bg--primary{
  //     background-color: var(--primary) !important;
  // }
  // .bg--secondary{
  //     background-color: var(--accent) !important;
  // }
  // .mat-progress-bar-fill::after {
  //     background-color: var(--primary) !important;
  // }

  .l-page-content {
    height: calc(100vh - 64px) !important;
    display: flex !important;
    flex-direction: column;
  }

  .l-content {
    padding: 1em 2em;
    position: relative;
  }

  .l-footer {
    margin-top: auto;
  }

  .button--fab {
    position: fixed;
    bottom: 40px;
    right: 40px;
  }

  .mat-spinner {
    display: flex;
    justify-content: center;
  }

  // .spinner svg,
  // .spinner .mat-spinner {
  //   width: 40px !important;
  //   height: 40px !important;
  // }
  // .spinner.spinner--sm svg,
  // .spinner.spinner--sm .mat-spinner {
  //   width: 20px !important;
  //   height: 20px !important;
  // }
  // .mat-spinner circle {
  //     stroke: var(--primary);
  // }

  @keyframes spinner {
    to {
      transform: rotate(360deg);
    }
  }

  .spinner:before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 50%;
    border: 2px solid #ffffff;
    border-top-color: #000000;
    animation: spinner 0.8s linear infinite;
  }

  .mdc-typography--headline4 {
    // display: flex;
    font-weight: 400;
    text-decoration: inherit;
    text-transform: inherit;
    font-size: 2.125rem;
    line-height: 2.5rem;
    letter-spacing: 0.0073529412em;
  }

  .table__input .mat-mdc-form-field-infix {
    width: 40px;
  }

  .grid-view .mat-header-cell {
    padding: 1.25em 0.8em;
  }

  .grid-view .mat-cell {
    padding: 0.625em 0.8em;
  }

  .grid-view mat-cell:first-of-type,
  .grid-view mat-header-cell:first-of-type {
    padding-left: 1.25em !important;
  }

  .grid-view [dir="rtl"] mat-cell:first-of-type:not(:only-of-type),
  .grid-view [dir="rtl"] mat-header-cell:first-of-type:not(:only-of-type) {
    padding-right: 1.25em !important;
  }

  .grid-view__discount {
    display: flex;
    align-items: center;
  }

  .grid-view__discount span {
    height: 20px;
    line-height: 20px;
    margin: 0 5px;
  }

  .grid-view input {
    width: 40px;
  }

  .price-discount-cell {
    display: flex;
    align-items: center;
  }

  .price-discount {
    text-decoration: line-through;
    font-size: 0.8em;
    margin-right: 10px;
  }

  .grid-view__description {
    width: 300px;
  }

  .grid-view__actions {
    width: 100%;
    background: var(--color-surface-5);
    display: flex;
    flex-direction: row;
    padding: 6px 20px;
    justify-content: space-between;
    align-items: center;
    color: var(--m3-sys-on-background);
    border-radius: var(--border-radius-rounded);

    div {
      display: flex;
    }

    @include media-breakpoint-down(sm) {
      display: block;

      .rounded-btn {
        margin-bottom: var(--space-s);
      }
    }
  }

  .cdk-global-overlay-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .sales-order__head-field .dropdown-menu {
    height: 400px;
    overflow: auto;
    max-width: 100%;
    margin-top: 10px;
    width: 100%;
  }

  .mat-list-item,
  .mat-list-option {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    // height: 48px;
    padding: 0 16px;
    text-align: left;
    text-decoration: none;
    max-width: 100%;
    position: relative;
    cursor: pointer;
    outline: none;
    display: flex;
    flex-direction: row;
    max-width: 100%;
    box-sizing: border-box;
    align-items: center;
    -webkit-tap-highlight-color: transparent;

    .mat-list-item-content {
      width: 100%;
    }
  }

  .full-width {
    width: 100% !important;
  }

  .bottom-sheet {
    border-top-right-radius: var(--border-radius-rounded);
    border-top-left-radius: var(--border-radius-rounded);
    box-sizing: border-box;
    outline: 0;
    max-height: 50vh;
    height: max-content;
    overflow: auto;
    box-shadow:
      0 8px 10px -5px rgba(0, 0, 0, 0.2),
      0 16px 24px 2px rgba(0, 0, 0, 0.14),
      0 6px 30px 5px rgba(0, 0, 0, 0.12);
    background: var(--m3-sys-background);
    color: var(--m3-sys-on-background);
    width: 30%;
    position: absolute;
    bottom: -24px;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 1000;
    transform: translate(0, 100%);
    transition: all 0.2s ease-in-out;
  }

  .bottom-sheet--shown {
    animation: showPanel 0.2s;
    animation-delay: 0.2s;
    animation-fill-mode: forwards;
    /* transform: translate(0, 0);  */
  }

  .bottom-sheet .nav-link {
    cursor: pointer;
  }

  @keyframes showPanel {
    from {
      transform: translate(0, 100%);
    }

    to {
      transform: translate(0, 0);
    }
  }

  .page-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2em;
    margin-top: 0;

    &--column {
      flex-direction: column;
      align-items: flex-start;
    }

    h4 {
      margin: 0;
      text-transform: capitalize !important;
    }

    a {
      margin-right: 8px;
      vertical-align: sub;
      color: var(--m3-sys-on-surface-variant);
    }

    &--wrap {
      @include media-breakpoint-down(sm) {
        display: block;

        h4 {
          margin-bottom: var(--space-m);
        }
      }
    }
  }

  .page-header__main-category {
    display: block;
    font-size: 16px;
    line-height: 1;
    color: $color-primary;
    margin-bottom: 10px;
  }

  @media print {

    .l-header,
    .l-side-nav,
    .l-footer,
    .page-header {
      display: none !important;
    }

    .layout {
      color: rgba(0, 0, 0, 0.87);
    }

    body .mat-icon {
      color: rgba(0, 0, 0, 0.87) !important;
    }

    .mat-drawer-content {
      overflow: initial;
    }

    .edara-chat {
      display: none !important;
    }

    .l-page-content {
      height: auto !important;
      max-width: 100vh;
    }

    [data-print="false"] {
      display: none !important;
    }

    [data-print="true"] {
      display: block !important;
    }

    body {
      padding: 0 !important;
      background-color: transparent !important;
    }

    .l-content {
      padding: 0;
    }

    .layout__body {
      padding: 0 !important;
      background-color: transparent !important;
    }

    .mat-drawer-content {
      margin-left: 0 !important;
    }

    .thermal-print {
      max-width: 400px;
      overflow-x: hidden;
    }

    .l-page-content {
      margin: auto !important;
    }
  }

  .table__checkbox-cell {
    max-width: 70px;
  }

  .table__menu {
    width: 170px;
  }

  .table__actions {
    max-width: 90px;
  }

  .table__actions-block {
    display: flex;

    @include breakpoint(x-large) {
      opacity: 0;
    }

    transition: opacity 0.3s ease-in-out;
  }

  /* .mat-icon-button{
    opacity: 0.54;
} */
  .mat-mdc-icon-button:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }

  .mat-row:hover {
    background-color: #f5f5f5;
  }

  .mat-mdc-row:hover .table__actions-block,
  .table__actions:hover .table__actions-block,
  .ag-row-hover .table__actions-block {
    opacity: 1;
  }

  .input__action-block {
    @include breakpoint(x-large) {
      opacity: 0;
    }

    transition: opacity 0.3s ease-in-out;
  }

  .mat-mdc-form-field-flex:hover .input__action-block {
    opacity: 1;
  }

  .invoice__price-cell {
    text-align: right;
  }

  .filter-panel {
    height: 100%;
    background-color: var(--m3-sys-background);
    color: var(--m3-sys-on-surface);
    border-radius: 5px;
    padding: 15px;

    .mdc-notched-outline__leading {
      display: none;
    }

    .mat-mdc-form-field-flex div {
      border: 0;
    }

    .mat-mdc-text-field-wrapper {
      border-bottom: 1px solid var(--m3-sys-on-surface);
      padding: 0 !important;
      margin-bottom: 1.41875em;
    }

    .mat-mdc-form-field-infix {
      padding: .3875em 0;
    }
  }

  .filter-panel__header {
    display: flex;
    align-items: center;
  }

  .filter-panel__header-action {
    @include ltr-prop(margin-left, margin-right, 0, auto);
  }

  .filter-panel__expand-action {
    position: absolute;
    width: auto;
    top: 23px;
    right: 0;
    opacity: 0;
    visibility: hidden;
    transform: translate(0, 10px);
    transition: all 0.2s ease-in-out;
    z-index: 9;

    &.is-shown {
      opacity: 1;
      visibility: visible;
      transform: translate(0, 0);
    }
  }

  .filter-panel__expand-action {
    top: 12px !important;
    right: -14px !important;
  }

  [dir="rtl"] .filter-panel__expand-action {
    left: -14px !important;
    right: auto !important;
  }

  .text-error--absolute {
    font-size: 75%;
    position: absolute;
    bottom: -20px;
    margin-left: 1em;
    top: 5em;

    mat-icon {
      font-size: 1em;
      height: 1em;
      width: 1em;
    }
  }

  .position-fixed {
    position: fixed !important;
  }

  .border-left {
    border-left: 1px solid rgba(0, 0, 0, 0.1);
  }

  [dir="rtl"] {
    .border-left {
      border-left: unset;
      border-right: 1px solid rgba(0, 0, 0, 0.1);
    }
  }

  .border-bottom {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  .border-dashed-bottom {
    border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
  }

  .fill-available {
    width: -webkit-fill-available;
  }

  .text-align-end {
    text-align: end !important;
  }

  .text-align-start {
    text-align: start !important;
  }

  .frame__select {
    line-height: 3em;
    border: 1px solid var(--m3-sys-outline);
    margin: 0em 1em 1em 1em;
    color: var(--m3-sys-outline);
    cursor: pointer;
    text-align: center;
  }

  .frame__select-active {
    border: 1px solid var(--m3-sys-primary);
    color: var(--m3-sys-primary);
  }

  .frame__select-separator {
    border-top: 1px solid var(--m3-sys-outline-variant);
    border-bottom: 0px;
    border-left: 0px;
    border-right: 0px;
  }

  .frame__select-separator-active {
    border-top: 1px solid var(--m3-sys-primary);
  }

  .insides__select {
    line-height: 3em;
    border: 1px solid var(--m3-sys-outline-variant);
    text-align: center;
    background-color: var(--m3-sys-surface);
    color: var(--m3-sys-on-surface);
    cursor: pointer;
    margin-inline: var(--space-xs);
    border-radius: 5px;
  }

  .insides__select-active {
    border: 1px solid var(--m3-sys-primary);
    background-color: var(--m3-sys-primary);
    color: var(--m3-sys-on-primary);
    fill: var(--m3-sys-on-primary);

    .mat-icon {
      color: var(--m3-sys-on-primary);
    }
  }

  .bg-round-default {
    background-color: var(--m3-sys-outline-variant);
    text-align: center;
    border-radius: 25px;
    color: var(--m3-sys-on-surface-variant);
    width: 100%;
    line-height: 2.5em;
  }

  .bg-round-primary {
    background-color: var(--color-surface-5);
    text-align: center;
    border-radius: 25px;
    color: var(--m3-sys-primary);
    width: 100%;
    line-height: 2.5em;
  }

  .bg-round-success {
    background-color: #d6f5dd;
    text-align: center;
    border-radius: 25px;
    color: #28a745;
    width: 100%;
    line-height: 2.5em;
  }

  .line-height-initial {
    line-height: initial;
  }

  .progress-primary {
    progress {
      -webkit-appearance: none;
      height: 0.5em;
    }

    progress::-webkit-progress-bar {
      background-color: var(--m3-sys-outline-variant);
    }

    progress::-webkit-progress-value {
      background-color: var(--m3-sys-primary);
    }
  }

  .progress-success {
    progress {
      -webkit-appearance: none;
      height: 0.5em;
    }

    progress::-webkit-progress-bar {
      background-color: var(--m3-sys-outline-variant);
    }

    progress::-webkit-progress-value {
      background-color: #28a745;
    }
  }

  .grid-filter-container {
    height: 100%;
    position: relative;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
  }

  .grid-filter-footer {
    margin-top: auto;
    align-self: flex-end;
  }

  .no-records-container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: var(--m3-sys-surface);
    padding: 4em;
    height: calc(100vh - 330px);

    @include media-breakpoint-down(sm) {
      padding: 10px;
    }
  }

  .no-records-icon {
    fill: var(--m3-sys-on-surface-variant);
  }

  .primary-stroked-button {
    border-color: #0091ea !important;
  }

  .primary-stroked-button:disabled {
    border-color: rgba(0, 0, 0, 0.26) !important;
  }

  .warn-stroked-button {
    border-color: #f44336 !important;
  }

  .warn-stroked-button:disabled {
    border-color: rgba(0, 0, 0, 0.26) !important;
  }

  .position-relative {
    position: relative !important;
  }

  .mat-select-separator {
    border-top: 1px solid #333333;
    border-bottom: 0px;
    border-left: 0px;
    border-right: 0px;
    margin: 0em 0.5em;
  }

  .line-height-normal {
    line-height: normal;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .matSuffix_txt {
    position: inherit;
    top: -5px;
  }

  .rotate-180 {
    transform: rotate(180deg);
  }

  .btn-success {
    background-color: #28a745 !important;
    color: white !important;
  }

  .btn-success:disabled {
    background: rgba(0, 0, 0, 0.26) !important;
  }

  .disableCssClass {
    background-color: rgba(0, 0, 0, 0.12) !important;
    border-color: rgba(0, 0, 0, 0.12) !important;
    color: rgba(0, 0, 0, 0.26) !important;
    pointer-events: none !important;
    cursor: not-allowed !important;
  }

  .customer-display-view {
    .l-page-content {
      height: 100vh !important;
      margin: 0 !important;
    }

    .l-page-content::before {
      content: "";
      position: absolute;
      background-image: url("#{$img-path}/cover.jpg");
      width: 100%;
      top: 0;
      left: 0;
      bottom: 0;
      background-size: 100% 100%;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      -webkit-filter: brightness(0.5);
      filter: brightness(0.5);
      z-index: -1;
    }

    #edarachat {
      display: none;
    }
  }

  .alert-warning {
    padding: 1em;
    margin-bottom: 2em;
    border: 1px solid transparent;
    border-radius: 4px;
    background-color: var(--m3-sys-tertiary-container);
    border-color: var(--m3-sys-tertiary-container);
    color: var(--m3-sys-on-tertiary-container);

    .mat-icon {
      color: var(--m3-sys-on-tertiary-container);
    }
  }

  .w-25 {
    width: 25% !important;
  }

  .w-50 {
    width: 50% !important;
  }

  .w-75 {
    width: 75% !important;
  }

  /* ag grid */
  .ag-theme-material {
    position: relative;
  }

  .ag-theme-material .ag-column-select,
  .ag-theme-material .ag-column-select-header {
    border-color: var(--m3-sys-surface-variant);
  }

  .ag-theme-material .ag-side-buttons {
    border-color: var(--m3-sys-surface-variant) !important;
  }

  .ag-theme-material .ag-menu {
    background-color: var(--m3-sys-background);
  }

  .ag-theme-material .ag-tabs-header,
  .ag-theme-material .ag-column-drop-horizontal {
    background-color: var(--m3-sys-surface-variant);
  }

  .ag-theme-material .ag-popup {
    background-color: transparent;
    position: absolute;
  }

  .ag-theme-material .ag-icon {
    color: var(--m3-sys-on-surface-variant);
  }

  .ag-theme-material input[class^="ag-"]:not([type]),
  .ag-theme-material input[class^="ag-"][type="text"],
  .ag-theme-material input[class^="ag-"][type="number"],
  .ag-theme-material input[class^="ag-"][type="tel"],
  .ag-theme-material input[class^="ag-"][type="date"],
  .ag-theme-material input[class^="ag-"][type="datetime-local"],
  .ag-theme-material textarea[class^="ag-"] {
    color: var(--m3-sys-on-surface);

    &::placeholder {
      color: var(--m3-sys-on-surface);
    }
  }

  .ag-theme-material .ag-menu-option-active,
  .ag-theme-material .ag-compact-menu-option-active {
    background-color: var(--m3-sys-surface-variant);
  }

  body .mat-mdc-paginator-page-size-select {
    margin-bottom: 0 !important;
    width: 70px !important;

    .mdc-notched-outline div {
      border: 0;
    }
  }

  .dark-theme {
    .cdk-overlay-dark-backdrop {
      background: var(--m3-state-layers-dark-on-background-opacity-16);
    }
  }

  .bg-sys-surface {
    background: var(--m3-sys-surface) !important;
  }

  .bg-surface-1 {
    background-color: var(--color-surface-1) !important;
  }

  .bg-surface-2 {
    background-color: var(--color-surface-2) !important;
  }

  .bg-surface-3 {
    background-color: var(--color-surface-3) !important;
  }

  .bg-surface-5 {
    background-color: var(--color-surface-5) !important;
  }

  .bg-sys-light-secondary-container {
    background-color: var(--m3-sys-light-secondary-container) !important;
  }

  .bg-success-soft {
    background-color: #dbefdc;
  }

  .bg-danger-soft {
    background-color: #feeceb;
  }

  #ngb-live {
    display: none;
  }

  a.mat-icon-button {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  body .mat-card {
    box-shadow: none !important;

    .mat-card-subtitle {
      color: var(--m3-sys-on-surface);
    }
  }

  body .mat-icon {
    color: var(--m3-sys-on-background);
  }

  body {
    .mat-icon {
      color: var(--m3-sys-on-background);
    }

    .icon-button--primary {
      .mat-icon {
        color: var(--m3-sys-on-primary);
      }
    }
  }

  body .table-height {
    max-height: calc(100vh - 315px);
    overflow: auto;
  }

  body .mat-error {
    font-size: var(--font-size-xs);
    text-transform: initial;
    color: var(--m3-sys-error);
  }

  .mat-badge-content {
    background: var(--m3-sys-outline);
  }

  .mat-badge-primary .mat-badge-content {
    color: var(--m3-sys-on-primary);
    background: var(--m3-sys-primary) !important;
  }

  body.customer-display-view {
    padding: 0;

    .layout__header {
      display: none;
    }

    .layout__nav {
      display: none;
    }

    .layout__content,
    .layout__body {
      background-color: var(--color-surface-5);
    }
  }

  .alert-danger {
    padding: 15px;
    border-radius: 4px;
    color: var(--m3-sys-on-error);
    background-color: var(--m3-sys-error);
    width: fit-content;

    mat-icon {
      color: var(--m3-sys-on-error) !important;
      margin-inline-end: var(--space-base);
    }
  }

  html.cdk-global-scrollblock {
    overflow: hidden;
  }

  .mat-select-panel .mat-option {
    font-size: var(--typo-body-large) !important;
  }

  .success_header {
    .mat-icon {
      color: #28a745;
    }
  }

  .dashed-border {
    border-top: 1px dashed #000;
  }

  [dir="rtl"] {
    .mat-form-field-label {
      line-height: 1.5 !important;
    }

    button.mat-flat-button:not(.mat-menu-trigger):not(.button--block):not(.mat-icon-button) .mat-button-wrapper,
    button.button--tonal:not(.button--block):not(.mat-icon-button) .mat-button-wrapper,
    .mat-stroked-button:not(.button--block):not(.mat-icon-button) .mat-button-wrapper {
      margin-top: -5px;
    }

    .mat-error {
      margin-top: -6px;
      line-height: 1.7;
    }
  }

  [dir="rtl"] {
    app-purchasing-search-products {
      .mat-error {
        transform: translate(-25px, -12px);
      }
    }

    .mat-custom-error {
      transform: translate(-25px, -12px) !important;
    }
  }

  .border-radius-50 {
    border-radius: 50% !important;
  }

  .uom-quantity-input,
  .item-discount-type-input {
    .mat-mdc-text-field-wrapper {
      padding: 0 .1em 0 .75em !important;
    }

    .mat-mdc-form-field-infix {
      width: 50%;
    }
  }

  .uom-quantity-input {
    .mat-mdc-form-field-icon-suffix {
      width: 50% !important;
      max-width: 8rem;
    }
  }

  [dir="rtl"] {

    .uom-quantity-input,
    .item-discount-type-input {
      .mat-mdc-text-field-wrapper {
        padding: 0 .75em 0 .1em !important;
      }
    }

  }

  .hidden-element {
    visibility: hidden;
  }

  .gap-2 {
    gap: 0.5rem;
  }

  .h-fit {
    height: fit-content !important;
  }

  .top-56 {
    top: 56px !important;
  }

  .text-gray {
    color: var(--gray-darkest) !important;
  }

  .text-disabled {
    opacity: 0.38;
  }

  .long-txt-trim {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    /* number of lines to show */
    -webkit-box-orient: vertical;
    line-height: initial;
    line-break: anywhere;
  }

  .view-on-hover {
    display: none;
    visibility: hidden;

    @include breakpoint(isMobile) {
      display: block !important;
      visibility: visible !important;
    }
  }

  .hint-text {
    color: var(--color);
  }

  .font-italic {
    font-style: italic !important;
  }

  .tertiary-pill {
    padding: 0.1rem 1rem;
    border-radius: 0.5rem;
    font-weight: var(--font-medium);
    font-size: var(--font-size-xs);
    background-color: var(--m3-sys-tertiary-container);
    color: var(--color-black);
  }

  .secondary-pill {
    padding: 0.1rem 1rem;
    border-radius: var(--border-radius-rounded);
    font-weight: var(--font-medium);
    font-size: var(--font-size-xs);
    background-color: var(--m3-sys-secondary-container);
    color: var(--m3-sys-on-surface);
  }